<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">类型列表</div>
        <el-button type="primary" @click="createOption()" class="cardTitleBtn"
          >新建类型</el-button
        >
      </el-col>
      <el-col
        :span="8"
        class="card_style"
        v-for="item in optionList"
        :key="item.guid"
      >
        <el-card class="box-card">
          <div class="clearfix">
            <span>{{ item.name }}</span>
            <el-button
              style="float: right; padding: 5px 0"
              type="text"
              @click="onDelete(item.guid, item.name)"
              ><i class="el-icon-delete"></i
            ></el-button>
            <el-button
              style="float: right; padding: 5px 0"
              type="text"
              @click="getDetail(item.guid, item.name)"
              ><i class="el-icon-edit"></i
            ></el-button>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-dialog title="新建或编辑" :visible.sync="dialogVisible" width="30%">
      <el-input v-model="itemValue"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveOrUpdate()">确 定</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>
<style>
.text {
  font-size: 14px;
}

.card_style {
  margin-top: 20px;
  padding-left: 40px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
</style>
<script>
export default {
  data() {
    return {
      userInfo: {},
      optionList: [],
      recordList: {},
      dialogVisible: false,
      itemValue: "",
      itemGuid: "",
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getOptionList();
  },
  methods: {
    getOptionList() {
      let self = this;
      self.$axios
        .get("/patrol-type/list", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.optionList = response.data.data;
          self.recordList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "巡检类型获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(guid, name) {
      this.dialogVisible = true;
      this.itemValue = name;
      this.itemGuid = guid;
    },
    createOption() {
      this.dialogVisible = true;
      this.itemValue = "";
      this.itemGuid = "";
    },
    saveOrUpdate() {
      let self = this;
      self.$axios
        .post(
          "/patrol-type/saveOrUpdate",
          {
            name: self.itemValue,
            guid: self.itemGuid,
          },
          {
            headers: { token: self.userInfo.token },
          }
        )
        .then(function (response) {
          console.log(response.data);
          if (response.data.status) {
            self.getOptionList();
            self.dialogVisible = false;
          }
        })
        .catch(function (error) {
          console.log(error);
          self.$message({
            message: "保存失败" + error.response.data.message,
            type: "warning",
          });
        });
    },
    onDelete(guid, title) {
      let self = this;
      self
        .$confirm("确定删除" + title + "吗？")
        .then(() => {
          self.$axios
            .delete("/patrol-type/delete", {
              data: { guid: guid },
              headers: { token: self.userInfo.token },
            })
            .then(function (response) {
              console.log(response);
              self.$message({
                message: "删除成功",
                type: "success",
              });
              self.getOptionList();
            })
            .catch(function (error) {
              console.log(error);
              self.$message({
                message: "删除失败" + error.response.data.message,
                type: "warning",
              });
            });
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>
</style>